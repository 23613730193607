<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    style="enable-background: new 0 0 100 100"
    xml:space="preserve"
  >
    <defs>
        <symbol viewbox="0 0 100 100" id="svg_model">
            <g id="model">
            <g>
                <path
                id=""
                class="st0"
                d="M82.32,59.16
                    c4.83-0.76,8.38-4.91,8.39-9.79v-5.18c0.08-5.48-4.3-9.99-9.78-10.06c-5.48-0.08-9.99,4.3-10.06,9.78c0,0.09,0,0.19,0,0.28v5.18
                    c0.01,4.88,3.57,9.04,8.39,9.79c-7.57,0.8-13.32,7.18-13.33,14.79V91h2.67V73.94c0-3.64,1.63-7.08,4.43-9.39l6.52,13.95
                    l-1.56,3.38l2.42,1.12l8.41-18.2c2.65,2.31,4.17,5.65,4.17,9.16V91h2.67V73.94C95.65,66.33,89.9,59.95,82.32,59.16 M73.53,49.36
                    v-5.18c-0.07-4.01,3.13-7.31,7.14-7.38c4.01-0.07,7.31,3.13,7.38,7.14c0,0.08,0,0.16,0,0.24v5.18c0.07,4.01-3.13,7.31-7.14,7.38
                    c-4.01,0.07-7.31-3.13-7.38-7.14C73.53,49.53,73.53,49.44,73.53,49.36 M78.07,66.43v2.61l-2.8-5.97c0.35-0.18,0.71-0.34,1.08-0.48
                    l1.72,2.13L78.07,66.43z M81.01,75.33l-0.27-0.58V69.1h0.47v5.55h0.11L81.01,75.33z M80.74,65.77h0.47v0.39h-0.47V65.77z
                    M81.74,63.1H80.2l-1-1.24c1.15-0.17,2.31-0.16,3.45,0.04L81.74,63.1z M83.89,69.12v-4.44l1.53-2.03c0.41,0.17,0.81,0.35,1.2,0.56
                    L83.89,69.12z"
                />
                <path
                id=""
                class="st0"
                d="M47.75,34.73h-7.74
                    c-0.66,0-1.2,0.54-1.2,1.2v11.86c0,0.66,0.54,1.2,1.2,1.2h7.74c0.66,0,1.2-0.54,1.2-1.2V35.92
                    C48.95,35.26,48.41,34.72,47.75,34.73 M46.55,46.58H41.2v-9.47h5.35L46.55,46.58z"
                />
                <path
                id=""
                class="st0"
                d="M47.75,50.13h-7.74
                    c-0.66,0-1.2,0.54-1.2,1.2v11.86c0,0.66,0.54,1.2,1.2,1.2h7.74c0.66,0,1.2-0.54,1.2-1.2V51.32
                    C48.95,50.66,48.41,50.12,47.75,50.13 M46.55,61.98H41.2v-9.46h5.35L46.55,61.98z"
                />
                <path
                id=""
                class="st0"
                d="M47.75,19.33h-7.74
                    c-0.66,0-1.2,0.54-1.2,1.2v11.86c0,0.66,0.54,1.2,1.2,1.2h7.74c0.66,0,1.2-0.54,1.2-1.2V20.52
                    C48.95,19.86,48.41,19.32,47.75,19.33 M46.55,31.18H41.2v-9.47h5.35L46.55,31.18z"
                />
                <path
                id=""
                class="st0"
                d="M36.07,19.33h-7.74
                    c-0.66,0-1.2,0.54-1.2,1.2v0v11.86c0,0.66,0.54,1.2,1.2,1.2l0,0h7.74c0.66,0,1.2-0.54,1.2-1.2l0,0V20.52
                    C37.27,19.86,36.73,19.32,36.07,19.33L36.07,19.33 M34.87,31.18h-5.36v-9.47h5.36V31.18z"
                />
                <path
                id="パス_120_00000130627246395209389850000009028721537411064228_"
                class="st0"
                d="M24.4,50.13h-7.74
                    c-0.66,0-1.2,0.54-1.2,1.2v11.86c0,0.66,0.54,1.2,1.2,1.2h7.74c0.66,0,1.2-0.54,1.2-1.2V51.32C25.59,50.66,25.06,50.12,24.4,50.13
                    M23.2,61.98h-5.35v-9.46h5.36L23.2,61.98z"
                />
                <path
                id=""
                class="st0"
                d="M24.4,34.73h-7.74
                    c-0.66,0-1.2,0.54-1.2,1.2l0,0v11.86c0,0.66,0.54,1.2,1.2,1.2h7.74c0.66,0,1.2-0.54,1.2-1.2V35.92
                    C25.59,35.26,25.06,34.72,24.4,34.73L24.4,34.73 M23.2,46.58h-5.35v-9.47h5.36L23.2,46.58z"
                />
                <path
                id=""
                class="st0"
                d="M36.07,50.13h-7.74
                    c-0.66,0-1.2,0.54-1.2,1.2v11.86c0,0.66,0.54,1.2,1.2,1.2h7.74c0.66,0,1.2-0.54,1.2-1.2V51.32
                    C37.27,50.66,36.73,50.12,36.07,50.13 M34.87,61.98h-5.36v-9.46h5.36V61.98z"
                />
                <path
                id=""
                class="st0"
                d="M24.4,19.33h-7.74
                    c-0.66,0-1.2,0.54-1.2,1.2v0v11.86c0,0.66,0.54,1.2,1.2,1.2h0h7.74c0.66,0,1.2-0.54,1.2-1.2l0,0V20.52
                    C25.59,19.86,25.06,19.32,24.4,19.33L24.4,19.33 M23.2,31.18h-5.35v-9.47h5.36L23.2,31.18z"
                />
                <path
                id=""
                class="st0"
                d="M36.07,34.73h-7.74
                    c-0.66,0-1.2,0.54-1.2,1.2l0,0v11.86c0,0.66,0.54,1.2,1.2,1.2h7.74c0.66,0,1.2-0.54,1.2-1.2V35.92
                    C37.27,35.26,36.73,34.72,36.07,34.73L36.07,34.73 M34.87,46.58h-5.36v-9.47h5.36V46.58z"
                />
                <path
                id=""
                class="st0"
                d="M63.41,77.56h-2.85V12.48l19.5,9.69
                    v9.5c0.24-0.01,0.49-0.04,0.73-0.04c0.65,0.01,1.29,0.06,1.93,0.17V21.34c0-0.51-0.28-0.97-0.74-1.2l-22.17-11h-0.03
                    C59.7,9.09,59.6,9.05,59.5,9.03l0,0C59.41,9.01,59.32,9,59.22,9H5.68c-0.74,0-1.33,0.6-1.33,1.33v68.56c0,0.74,0.6,1.33,1.33,1.33
                    h57.73V77.56z M57.89,77.56H7.01V11.67h50.88L57.89,77.56z"
                />
            </g>
            </g>
        </symbol>
        <symbol viewbox="0 0 100 100" id="svg_program">
            <g id="program">
            <g>
                <path
                id=""
                class="st0"
                d="M6.76,11.73h51.01v66.93h-6.39V66.95
                    h-4.12v11.71h-2.65V66.95h-4.12v11.71h-2.63V66.95h-4.12v11.71h-2.64V66.95h-3.8v11.71h-2.64V66.95h-4.12v11.71h-2.64V66.95H13.8
                    v11.71H6.76V11.73z M4.04,9.01v72.37h60.27v-2.72H60.6V12.61l19.35,9.51v9.39l2.72,0.46V20.41L60.6,9.57l-1.15-0.57H4.04z"
                />

                <rect
                id=""
                x="13.8"
                y="21.45"
                class="st0"
                width="4.12"
                height="12.54"
                />

                <rect
                id=""
                x="20.55"
                y="21.45"
                class="st0"
                width="4.12"
                height="12.54"
                />

                <rect
                id=""
                x="27.3"
                y="21.45"
                class="st0"
                width="3.8"
                height="12.54"
                />

                <rect
                id=""
                x="13.8"
                y="36.63"
                class="st0"
                width="4.12"
                height="12.5"
                />

                <rect
                id=""
                x="20.55"
                y="36.63"
                class="st0"
                width="4.12"
                height="12.5"
                />

                <rect
                id=""
                x="27.3"
                y="36.63"
                class="st0"
                width="3.8"
                height="12.5"
                />
                <path
                id=""
                class="st0"
                d="M17.91,51.8v-0.04H13.8v12.54h4.12
                    V51.8z"
                />
                <path
                id=""
                class="st0"
                d="M24.67,51.8v-0.04h-4.12v12.54h4.12
                    L24.67,51.8z"
                />
                <path
                id=""
                class="st0"
                d="M31.11,51.8v-0.04h-3.8v12.54h3.8V51.8
                    z"
                />
                <path
                id=""
                class="st0"
                d="M37.86,33.99V21.45h-4.12v12.54
                    L37.86,33.99L37.86,33.99z"
                />

                <rect
                id=""
                x="40.5"
                y="21.45"
                class="st0"
                width="4.12"
                height="12.54"
                />

                <rect
                id=""
                x="47.25"
                y="21.45"
                class="st0"
                width="4.12"
                height="12.54"
                />
                <path
                id=""
                class="st0"
                d="M37.86,49.13v-12.5h-4.12v12.5H37.86z"
                />

                <rect
                id=""
                x="40.5"
                y="36.63"
                class="st0"
                width="4.12"
                height="12.5"
                />

                <rect
                id=""
                x="47.25"
                y="36.63"
                class="st0"
                width="4.12"
                height="12.5"
                />
                <path
                id=""
                class="st0"
                d="M37.86,64.31V51.76h-4.12v12.54H37.86z
                    "
                />
                <path
                id=""
                class="st0"
                d="M44.61,51.8v-0.04h-4.12v12.54h4.12
                    L44.61,51.8z"
                />
                <path
                id=""
                class="st0"
                d="M51.37,51.76h-4.12v12.54h4.12V51.76z"
                />
                <g
                id=""
                >
                <path
                    id=""
                    class="st0"
                    d="M82.37,58.51
                        c4.92-0.77,8.55-5.01,8.56-9.99v-5.29c0.08-5.59-4.39-10.19-9.98-10.27c-5.59-0.08-10.19,4.39-10.27,9.98c0,0.09,0,0.19,0,0.28
                        v5.29c0.01,4.98,3.64,9.22,8.56,9.99c-7.72,0.81-13.59,7.32-13.6,15.09v17.4h2.72v-17.4c0-3.71,1.66-7.23,4.52-9.59l6.66,14.24
                        l-1.6,3.45l2.47,1.14l8.58-18.58c2.7,2.36,4.25,5.76,4.25,9.35v17.4h2.72V73.59C95.95,65.83,90.09,59.33,82.37,58.51
                        M86.73,62.66l-2.78,6.02v-4.53l1.56-2.07C85.93,62.25,86.34,62.44,86.73,62.66 M88.2,43.23v5.29c0,4.09-3.32,7.4-7.4,7.4
                        c-4.09,0-7.4-3.32-7.4-7.4v-5.29c0-4.09,3.32-7.4,7.4-7.4C84.89,35.82,88.2,39.14,88.2,43.23 M80.8,61.15
                        c0.63,0,1.27,0.06,1.89,0.16l-0.92,1.22h-1.58l-1.02-1.27C79.71,61.19,80.25,61.15,80.8,61.15 M81.34,74.32l-0.32,0.69l-0.28-0.6
                        v-5.75h0.48v5.67L81.34,74.32z M80.74,65.25h0.48v0.4h-0.48V65.25z M76.28,62.01l1.75,2.18v4.41l-2.86-6.1
                        C75.53,62.32,75.89,62.16,76.28,62.01"
                />
                </g>
            </g>
            </g>
        </symbol>
        <symbol viewbox="0 0 100 100" id="svg_shokibo">
            <g id="shokibo">
            <g>
                <rect
                id=""
                x="18.79"
                y="33.93"
                class="st0"
                width="12.99"
                height="5.16"
                />

                <rect
                id=""
                x="34.46"
                y="33.93"
                class="st0"
                width="12.99"
                height="5.16"
                />

                <rect
                id=""
                x="50.13"
                y="33.93"
                class="st0"
                width="12.99"
                height="5.16"
                />
                <path
                id=""
                class="st0"
                d="M68.45,55.39h-5.33V41.77H50.13v13.62
                    h-2.68V41.77H34.47v13.62h-2.68V41.77H18.8v13.62H8.84V25.41h64.68v2.04c0.88-0.42,1.81-0.74,2.76-0.93V13.56H6.07v44.59h59.49
                    C66.42,57.11,67.39,56.18,68.45,55.39 M8.85,16.32h64.68v6.32H8.84L8.85,16.32z"
                />
                <path
                id=""
                class="st0"
                d="M80.11,53.44
                    c5-0.79,8.69-5.09,8.69-10.15v-5.37c0-5.68-4.6-10.29-10.29-10.29c-5.68,0-10.29,4.6-10.29,10.29v5.37
                    c0.01,5.06,3.7,9.36,8.69,10.15c-7.85,0.82-13.8,7.43-13.81,15.32v17.67h2.76V68.77c0-3.77,1.69-7.34,4.6-9.74l6.76,14.46
                    l-1.62,3.5l2.51,1.16l8.72-18.87c2.74,2.39,4.32,5.85,4.32,9.49v17.67h2.76V68.77C93.92,60.88,87.96,54.27,80.11,53.44 M71,43.29
                    v-5.37c0-4.15,3.37-7.52,7.52-7.52c4.15,0,7.52,3.37,7.52,7.52v5.37c0,4.15-3.37,7.52-7.52,7.52C74.37,50.81,71,47.45,71,43.29
                    L71,43.29 M75.7,60.99v2.71l-2.9-6.19c0.36-0.19,0.73-0.35,1.12-0.5l1.78,2.21L75.7,60.99z M78.75,70.2l-0.28-0.6v-5.85h0.49v5.76
                    h0.11L78.75,70.2z M78.47,60.29h0.49v0.4h-0.49V60.29z M79.5,57.53h-1.6l-1.04-1.29c1.19-0.17,2.4-0.16,3.58,0.04L79.5,57.53z
                    M81.72,63.77v-4.6l1.6-2.1c0.42,0.17,0.84,0.37,1.24,0.58L81.72,63.77z"
                />
            </g>
            </g>
        </symbol>
    </defs>
  </svg>
</template>

<script>
// @ is an alias to /src

export default {
  name: "SvgIcon",
  components: {},
};
</script>

<style scoped>
svg {
    display: none;
}
.st0{
    /*fill: red;*/
}
</style>
