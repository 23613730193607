<template>
<div class="bl_header">
    <header class="bl_header_header">
        <svg viewBox="0 0 100 100" class="svg_program"><use xlink:href="#svg_program"></use></svg>
        <h1><router-link to="/" class="uq_headerLink"><span class="text-no-wrap">非住宅建築物に関する</span><span class="text-no-wrap">省エネルギー基準に</span><span class="text-no-wrap">準拠したプログラム</span></router-link></h1>
    </header>
</div>
</template>


<script>
export default {
    name: 'layout-header',
    components: {
    },
}
</script>

<style scoped>
.bl_header{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
}
.bl_header_header{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.el_header_title{
    font-size: clamp(16px, 3vw, 26px);
    line-height: clamp(16px, 3vw, 26px);
    font-weight: 500;
    letter-spacing: 5px;
    white-space: break-spaces;
}
.bl_header_header h1{
    margin: 18px 32px;  font-size: clamp(16px, 3vw, 26px);
    line-height: clamp(16px, 3vw, 26px);
    font-weight: 500;
    letter-spacing: 4.94px;
    color: #555555;
}
.bl_header_header h1 a:hover{
    font-weight: 500;
}
.bl_header_header h1 .uq_headerLink{
    display: flex;
    flex-wrap: wrap;
}
.bl_header .svg_program{
    width: 50px;
    min-width: 36px;
    height: 50px;
    min-height: 36px;
    margin-left: 32px;
    fill: #857348;
}
</style>
