<template>
  <div class="temp_common">
    <layout-header />
    
    <nav class="bl_header_nav">
        <router-link to="/" class="hp_invisible__tb">非住宅建築物に関する省エネルギー基準に準拠したプログラムTOP</router-link>
        <router-link to="/contact">サポート・お問い合わせ</router-link>
    </nav>
    <main>
      <slot></slot>
    </main>
    <layout-footer />
  </div>
</template>


<script>
import LayoutHeader from '../organisms/LayoutHeader.vue'
import LayoutFooter from '../organisms/LayoutFooter.vue'


export default {
  name: 'temp-common',
  components: {
    LayoutHeader, LayoutFooter
  },

  data: () => ({
  }),

};
</script>

<style scoped>

</style>