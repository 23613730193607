<template>
<footer class="bl_footer">
    <nav class="bl_footer_nav">
        <router-link to="/about">プログラムについて</router-link>
        <router-link to="/requirement">推奨環境</router-link>
        <router-link to="/disclaimer">免責事項</router-link>
        <router-link to="/links">関連リンク</router-link>
        <router-link to="/contact">サポート・お問い合わせ</router-link>
    </nav>
</footer>
</template>


<script>
export default {
    name: 'layout-footer',
    components: {
    },
}
</script>

<style scoped>
.bl_footer{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #555555;
    width: 100%;
    padding: 16px;
}
.bl_footer_nav{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 32px;
    row-gap: 16px;
    width: 100%;
    font-size: 14px;
}

.bl_footer_nav >>> a{
    color: white;
}
</style>
