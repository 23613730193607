<template>
<temp-common>
  <div class="bl_home">
    <div class="bl_indexImgWrapper">
      <div class="bl_indexImgWrapper_content">
        <div class="bl_indexImgWrapper_btn">
          <router-link to="./program" class="el_headerBtn">現行版</router-link>
          <router-link to="./program-beta" class="el_headerBtn el_headerBtn__beta el_btn__comingsoon">次期更新版</router-link>
        </div>
        <div class="bl_history ly_history">
          <div class="bl_history_content">
            <div class="bl_history_header">
              <h3 style="display: inline-flex;" class="text-no-wrap">お知らせ</h3>
              <small
                >各種プログラムの更新は、原則として年2回（毎年4月と10月）とします。</small
              >
            </div>
            <div class="bl_history_unit ver_regular">
              <div class="bl_history_unit_date"><time>2024.10.02</time></div>
              <div class="bl_history_unit_summary">
                <p>以下のプログラムを公開しました。</p>
                <ul>
                  <li>エネルギー消費性能計算プログラム(非住宅版) 標準入力法 Ver.3.7.1</li>
                  <li>エネルギー消費性能計算プログラム(非住宅版) モデル建物法 Ver.3.7.1</li>
                </ul>
                <a href="releasenote/update_20241002.pdf" target="_blank" rel="noopener noreferrer">（ プログラムの変更内容 ）</a>
              </div>
            </div>
            <div class="bl_history_unit ver_regular">
              <div class="bl_history_unit_date"><time>2024.10.01</time></div>
              <div class="bl_history_unit_summary">
                <p>以下のプログラムを公開しました。</p>
                <ul>
                  <li>エネルギー消費性能計算プログラム(非住宅版) 標準入力法 Ver.3.7</li>
                  <li>エネルギー消費性能計算プログラム(非住宅版) モデル建物法 Ver.3.7</li>
                </ul>
                <a href="releasenote/update_20241001.pdf" target="_blank" rel="noopener noreferrer">（ プログラムの変更内容 ）</a>
              </div>
            </div>
            <div class="bl_history_unit ver_beta">
              <div class="bl_history_unit_date"><time>2024.09.12</time></div>
              <div class="bl_history_unit_summary">
                <p>以下の資料を次期更新版ページで公開しました。</p>
                <ul>
                  <li>標準入力法の入力マニュアル</li>
                  <li>モデル建物法の入力マニュアル</li>
                </ul>
              </div>
            </div>
            <div class="bl_history_unit ver_beta">
              <div class="bl_history_unit_date"><time>2024.09.09</time></div>
              <div class="bl_history_unit_summary">
                <p>モデル建物法（小規模版）の入力マニュアルを次期更新版ページで公開しました。</p>
              </div>
            </div>
            <div class="bl_history_unit ver_beta">
              <div class="bl_history_unit_date"><time>2024.09.09</time></div>
              <div class="bl_history_unit_summary">
                <p>以下のβ版プログラムを公開しました。</p>
                <ul>
                  <li>エネルギー消費性能計算プログラム(非住宅版) 標準入力法 Ver.3.7β2</li>
                  <li>エネルギー消費性能計算プログラム(非住宅版) モデル建物法 Ver.3.7β2</li>
                </ul>
                <a href="releasenote/update_20240909.pdf" target="_blank" rel="noopener noreferrer">（ プログラムの変更内容 ）</a>
              </div>
            </div>
            <div class="bl_history_unit ver_beta">
              <div class="bl_history_unit_date"><time>2024.07.31</time></div>
              <div class="bl_history_unit_summary">
                <p>以下のβ版プログラムを公開しました。</p>
                <ul>
                  <li>エネルギー消費性能計算プログラム(非住宅版) 標準入力法 Ver.3.7β1</li>
                  <li>エネルギー消費性能計算プログラム(非住宅版) モデル建物法 Ver.3.7β1</li>
                </ul>
                <a href="releasenote/update_20240731.pdf" target="_blank" rel="noopener noreferrer">（ プログラムの変更内容 ）</a>
              </div>
            </div>
            <div class="bl_history_unit ver_regular">
              <div class="bl_history_unit_date"><time>2024.04.01</time></div>
              <div class="bl_history_unit_summary">
                <p>以下のプログラムを公開しました。</p>
                <ul>
                  <li>エネルギー消費性能計算プログラム(非住宅版) 標準入力法 Ver.3.6</li>
                  <li>エネルギー消費性能計算プログラム(非住宅版) モデル建物法 Ver.3.6</li>
                  <li>エネルギー消費性能計算プログラム(非住宅版) 小規模版モデル建物法 Ver.3.6</li>
                </ul>
                <a href="releasenote/update_20240401.pdf" target="_blank" rel="noopener noreferrer">（ プログラムの変更内容 ）</a><br />
              </div>
            </div>
            <div class="bl_history_unit ver_beta">
              <div class="bl_history_unit_date"><time>2024.03.04</time></div>
              <div class="bl_history_unit_summary">
                <p>以下の資料を次期更新版ページで公開しました。</p>
                <ul>
                  <li>標準入力法の入力マニュアル</li>
                  <li>モデル建物法の入力マニュアル</li>
                  <li>軽微な変更の判断の仕方について</li>
                  <li>既存建築物の増改築時における省エネ性能の算定の考え方について</li>
                </ul>
              </div>
            </div>
            <div class="bl_history_unit ver_beta">
              <div class="bl_history_unit_date"><time>2024.02.27</time></div>
              <div class="bl_history_unit_summary">
                <p>以下のβ版プログラムを公開しました。</p>
                <ul>
                  <li>エネルギー消費性能計算プログラム(非住宅版) 標準入力法 Ver.3.6β2</li>
                  <li>エネルギー消費性能計算プログラム(非住宅版) モデル建物法 Ver.3.6β2</li>
                </ul>
                <a href="releasenote/update_20240227.pdf" target="_blank" rel="noopener noreferrer">（ プログラムの変更内容 ）</a>
              </div>
            </div>
            <div class="bl_history_unit ver_beta">
              <div class="bl_history_unit_date"><time>2024.01.17</time></div>
              <div class="bl_history_unit_summary">
                <p>以下のβ版プログラムを公開しました。</p>
                <ul>
                  <li>エネルギー消費性能計算プログラム(非住宅版) 標準入力法 Ver.3.6β</li>
                  <li>エネルギー消費性能計算プログラム(非住宅版) モデル建物法 Ver.3.6β</li>
                </ul>
                <a href="releasenote/update_20240117.pdf" target="_blank" rel="noopener noreferrer">（ プログラムの変更内容 ）</a>
              </div>
            </div>
            <div class="bl_history_unit ver_regular">
              <div class="bl_history_unit_date"><time>2023.10.02</time></div>
              <div class="bl_history_unit_summary">
                <p>以下のプログラムを公開しました。</p>
                <ul>
                  <li>エネルギー消費性能計算プログラム(非住宅版) 標準入力法 Ver.3.5</li>
                  <li>エネルギー消費性能計算プログラム(非住宅版) モデル建物法 Ver.3.5</li>
                  <li>エネルギー消費性能計算プログラム(非住宅版) 小規模版モデル建物法 Ver.3.5</li>
                </ul>
                <a href="releasenote/update_20231002.pdf" target="_blank" rel="noopener noreferrer">（ プログラムの変更内容 ）</a><br />
              </div>
            </div>
            <div class="bl_history_unit ver_beta">
                <div class="bl_history_unit_date"><time>2023.09.22</time></div>
                <div class="bl_history_unit_summary">
                  <p>軽微な変更用の確認シートとその説明資料を次期更新版ページで公開しました。</p>
                </div>
              </div>
            <div class="bl_history_unit ver_beta">
              <div class="bl_history_unit_date"><time>2023.09.21</time></div>
              <div class="bl_history_unit_summary">
                <p>以下のβ版プログラムを公開しました。</p>
                <ul>
                  <li>エネルギー消費性能計算プログラム(非住宅版)  標準入力法 Ver.3.5β4</li>
                  <li>エネルギー消費性能計算プログラム(非住宅版)  モデル建物法 Ver.3.5β4</li>
                </ul>
                <a href="releasenote/update_20230921.pdf" target="_blank" rel="noopener noreferrer">（ プログラムの変更内容 ）</a>
              </div>
            </div>
            <div class="bl_history_unit ver_beta">
              <div class="bl_history_unit_date"><time>2023.09.21</time></div>
              <div class="bl_history_unit_summary">
                <p>次期更新版の入力マニュアル(標準入力法・モデル建物法)を公開しました。</p>
              </div>
            </div>
            <div class="bl_history_unit ver_beta">
              <div class="bl_history_unit_date"><time>2023.08.07</time></div>
              <div class="bl_history_unit_summary">
                <p>以下のβ版プログラムを公開しました。</p>
                <ul>
                  <li>エネルギー消費性能計算プログラム(非住宅版)  標準入力法 Ver.3.5β3</li>
                  <li>エネルギー消費性能計算プログラム(非住宅版)  モデル建物法 Ver.3.5β3</li>
                </ul>
                <a href="releasenote/update_20230807.pdf" target="_blank" rel="noopener noreferrer">（ プログラムの変更内容 ）</a>
              </div>
            </div>
            <div class="bl_history_unit ver_beta">
              <div class="bl_history_unit_date"><time>2023.06.21</time></div>
              <div class="bl_history_unit_summary">
                <p>以下のβ版プログラムを公開しました。</p>
                <ul>
                  <li>エネルギー消費性能計算プログラム(非住宅版) 標準入力法 Ver.3.5β2</li>
                  <li>エネルギー消費性能計算プログラム(非住宅版) モデル建物法 Ver.3.5β2</li>
                </ul>
                <a href="releasenote/update_20230621.pdf" target="_blank" rel="noopener noreferrer">（ プログラムの変更内容 ）</a>
              </div>
            </div>
            <div class="bl_history_unit ver_regular">
              <div class="bl_history_unit_date"><time>2023.06.06</time></div>
              <div class="bl_history_unit_summary">
                <p>以下のプログラムを公開しました。</p>
                <ul>
                  <li>エネルギー消費性能計算プログラム(非住宅版) 標準入力法 Ver.3.4.1</li>
                  <li>エネルギー消費性能計算プログラム(非住宅版) モデル建物法 Ver.3.4.1</li>
                  <li>エネルギー消費性能計算プログラム(非住宅版) 小規模版モデル建物法 Ver.3.4.1</li>
                </ul>
                <a href="releasenote/update_20230606.pdf" target="_blank" rel="noopener noreferrer">（ プログラムの変更内容 ）</a>
              </div>
            </div>
            <div class="bl_history_unit ver_beta">
              <div class="bl_history_unit_date"><time>2023.05.22</time></div>
              <div class="bl_history_unit_summary">
                <p>以下のβ版プログラムを公開しました。</p>
                <ul>
                  <li>エネルギー消費性能計算プログラム(非住宅版) 標準入力法 Ver.3.5β</li>
                  <li>エネルギー消費性能計算プログラム(非住宅版) モデル建物法 Ver.3.5β</li>
                </ul>
                
                <a href="releasenote/update_20230522.pdf" target="_blank" rel="noopener noreferrer">（ プログラムの変更内容 ）</a>
              </div>
            </div>
            <div class="bl_history_unit ver_regular">
              <div class="bl_history_unit_date"><time>2023.04.17</time></div>
              <div class="bl_history_unit_summary">
                <p>以下の入力ツールおよびサンプルの不具合を修正しました。</p>
                <ul>
                  <li>モデル建物法入力シート（R05.04.03公開）</li>
                  <li>事務所モデル（モデル建物法）サンプル（R05.04.03公開）</li>
                </ul>
                <p>修正版（R05.04.17公開）をご使用ください。
                </p>
              </div>
            </div>
            <div class="bl_history_unit ver_regular">
              <div class="bl_history_unit_date"><time>2023.04.03</time></div>
              <div class="bl_history_unit_summary">
                <p>以下のプログラムを公開しました。</p>
                <ul>
                  <li>エネルギー消費性能計算プログラム(非住宅版) 標準入力法 Ver.3.4.0</li>
                  <li>エネルギー消費性能計算プログラム(非住宅版) モデル建物法 Ver.3.4.0</li>
                  <li>エネルギー消費性能計算プログラム(非住宅版) 小規模版モデル建物法 Ver.3.4.0</li>
                </ul>
                <a href="releasenote/Ver340.pdf" target="_blank" rel="noopener noreferrer">（ プログラムの変更内容 ）</a><br />
              </div>
            </div>
          </div>
          <div class="bl_history_archiveLink">
            <router-link to="/history">過去のお知らせ</router-link>
          </div>
        </div>
      </div>
    </div>
    <h2 class="el_h2">エネルギー消費性能・外皮性能の評価をサポート</h2>
    <div class="bl_container bl_container__2column">
      <div class="bl_container__2column_child">
        <ul class="el_list el_list__indexpage">
          <li>建物や設備機器について、条件を選択・入力すると、非住宅のエネルギー消費性能や外皮性能が評価できます。</li>
          <li>計算方法は、H28年省エネルギー基準に準拠しています。</li>
          <li>計算結果は、公的な届出や補助金の申請に利用できます。</li>
        </ul>
      </div>
      <div class="bl_container__2column_child">
        <div class="uq_functionIllust02"></div>
      </div>
    </div>
    <h2 class="el_h2">API経由で他のソフトウェアと連携可能</h2>
    <div class="bl_container bl_container__2column">
      <div class="bl_container__2column_child">
        <div class="uq_functionIllust01"></div>
      </div>
      <div class="bl_container__2column_child bl_container__2column_child__column">
        <ul class="el_list el_list__indexpage">
          <li>API (Application Programming Interface) を提供しています。</li>
          <li>APIを利用した開発を行うことにより、他のソフトウェアと連携することができます。</li>
        </ul>
        <router-link to="/program-api" class="el_btn el_btn__big">APIについて</router-link>
      </div>
    </div>
  </div>
</temp-common>
</template>

<script>
import TempCommon from '@/components/templates/TempCommon.vue'
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
    TempCommon
    
  }
}
</script>

<style scoped>
.bl_indexImgWrapper{
  background: url(../assets/img/building_top_h600.png);
  background-size: cover;
  background-position: center;
}
.el_headerBtn.el_headerBtn__beta{
  color:white;
  background-color: #857348;
}
.uq_functionIllust01{
  width: 300px;
  min-width: 300px;
  height: 300px;
  min-height: 300px;
  background: url(../assets/img/Building_illust_cut_fuction_illust_set.png);
  background-size: auto 300px;
  background-position: 0 0;
}
.uq_functionIllust02{
  width: 300px;
  min-width: 300px;
  height: 300px;
  min-height: 300px;
  background: url(../assets/img/Building_illust_cut_fuction_illust_set.png);
  background-size: auto 300px;
  background-position: 300px 0;
}
.bl_container__2column_child__column{
  flex-direction: column;
  align-items: center;
}
@media (max-width: 960px){ /* タブレットサイズ以下 */
.bl_indexImgWrapper{
  background: url(../assets/img/building_top_h600.png);
  background-size: auto clamp(80vh, 500px, 100%);
  background-repeat: no-repeat;
  background-position: top center;
}
.bl_home .bl_indexImgWrapper_content{
  grid-template-columns: 1fr;
  row-gap: 48px;
  padding: 32px 0;
}
.bl_home .el_headerBtn{
  margin-top: 48px;
  margin-bottom: 48px;}
.bl_home .bl_history.ly_history{
  height: 100%;
}
}
</style>
